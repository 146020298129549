
.game-card-default {
  aspect-ratio: 3 / 4;
  border-radius: 12pt;
  width: 100%;
  position: relative;
  overflow: hidden;
  user-select: none;

  * {
      user-select: none;
  }

  img {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      aspect-ratio: 3 / 4;
      transition: all 0.5s ease-in-out;
  }

  background-size: contain;
  background-position: center !important;
  transition: all 0.5s ease-in-out !important;

  button {
      position: absolute;
      left: 1rem;
      right: 1rem;
      bottom: 1rem;
      padding: 0.75rem;
      z-index: 2;
      opacity: 0;
      transition: all 0.5s ease-in-out;
      cursor: pointer;
      font-weight: 900;
      font-size: 1.5rem;
  }
}

.game-card-default:hover {
  img {
      transform: scale(1.10);
      transform-origin: center center;
  }

  @media (min-width:768px) {
      button {
          opacity: 1;
          font-weight: 600;
      }
  }
}
