.card {
    width: 130px;
    height: 66px;
    position: relative;
    border-radius: 14px;
    background: #EFEFEE;
    overflow: hidden;
  }
  
  .imagePlaceholder {
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #EFEFEE 25%, #D4D4D3 50%, #EFEFEE 75%);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite;
  }
  
  @keyframes shimmer {
    0% { background-position: 200% 0; }
    100% { background-position: -200% 0; }
  }