.card {
    width: 130px;
    height: 66px;
    position: relative;
    border-radius: 14px;
    background: var(--card-bg);
    overflow: hidden;
}

.image {
    object-fit: contain;
}

.cardBig {
    width: 100%;
    height: 100px;

    @media (max-width: 768px) {
      height: 54px;
  }
}
