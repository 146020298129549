.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  user-select: none;
  position: relative;
  -webkit-backface-visibility: hidden;
  padding-bottom: 1rem;

  * {
    user-select: none;
    -webkit-backface-visibility: hidden;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .content {
    position: relative;
  }

  /* Fade effect on the left */
  .leftFade,
  .rightFade {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 2rem;
    pointer-events: none;
    z-index: 2;
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  .leftFade {
    left: 0;
    background: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
  }

  .rightFade {
    right: 0;
    background: linear-gradient(to left, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
  }

  /* Show fades by changing opacity */
  .showFade {
    opacity: 1;
  }

  .leftRightButtons {
    transition: opacity 0.3s ease-in-out;
    display: none;
    opacity: 0;
  }

  .leftRightButtons.visible {
    display: flex;
    opacity: 1;
  }

  .viewAll {
    cursor: pointer;
    text-decoration: none;
  }

  .carouselWrapper {
    width: 100%;
    overflow-x: hidden;
    scrollbar-width: none;
    user-select: none;

    * {
      user-select: none;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    .carouselContent {
      display: flex;
      gap: 1rem;
      flex-wrap: nowrap;
      transition: transform 0.3s ease-in-out;
    }
  }

  .card {
    flex: 0 0 auto;

    img {
      pointer-events: none;
    }
  }
}

@media (max-width: 767px) {
  .wrapper {
    .header {
      padding-right: 1rem;
      padding-left: 1rem;
    }
    .content {
      .carouselWrapper {
        .carouselContent {
          padding-left: 1rem; /* Apply padding to the content */

          /* Add an invisible spacer at the end */
          &::after {
            content: '';
            flex: 0 0 1px; /* Fake spacer at the end */
          }
        }
      }
    }
  }
}
