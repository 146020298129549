.wrapper {


  // Overwrite the tailwind, messes with the info pages
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: revert;
    font-weight: revert;
    margin: revert;
  }

  .content * {

    span,
    p {
      color: #000;
    }


    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    b,
    strong {
      color: #4A26FF;
    }


    a:link {
      color: #4A26FF;
      text-decoration: none;
    }

    a:visited {
      color: #551A8B;
    }

    a:hover {
      color: #4A26FF;
      font-weight: 700;
      text-decoration: underline;
    }

    a:active {
      color: #4A26FF;
      font-weight: 700;
    }

    *::marker {
      color: #000;
    }

    table {
      width: 100%;
      border-collapse: collapse;
      margin-bottom: 1.5rem;
    }

    th,
    td {
      border: 1px solid #ccc;
      padding: 8px;
      text-align: left;
    }

    th {
      background-color: #f4f4f4;
      font-weight: bold;
    }

    tr:nth-child(even) {
      background-color: #f9f9f9;
    }

    table caption {
      caption-side: top;
      font-weight: bold;
      text-align: left;
      margin-bottom: 0.5rem;
    }

    table {
      font-size: 1rem;
      line-height: 1.5;
      color: #333;
    }

    ul li::marker {
      color: #4A26FF;
    }

  }
}
