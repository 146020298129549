.skeletonCard {
    aspect-ratio: 3 / 4;
    border-radius: 12pt;
    width: 100%;
    position: relative;
    overflow: hidden;
    user-select: none;
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite;
  }
  
  @keyframes shimmer {
    0% { background-position: 200% 0; }
    100% { background-position: -200% 0; }
  }